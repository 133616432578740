<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Projet <strong>#{{projet.id}}</strong></h3>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-success mr-1" @click="save()">
            <i class="icon-md fas fa-save"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Detail</h3>
            </div>
          </div>
          <div class="card-body">
            <b-form>
              <b-form-group label="Nom:">
                <b-form-input v-model="projet.nom"></b-form-input>
              </b-form-group>
              <b-form-group label="Url:">
                <b-form-input v-model="projet.info.url"></b-form-input>
              </b-form-group>
              <b-form-group label="Logo:">
                <b-form-input v-model="projet.info.logo"></b-form-input>
              </b-form-group>
              <b-form-group label="Couleur primaire:">
                <b-form-input v-model="projet.info.couleur_primaire" type="color"></b-form-input>
              </b-form-group>
              <b-form-group label="Couleur secondaire:">
                <b-form-input v-model="projet.info.couleur_secondaire" type="color"></b-form-input>
              </b-form-group>
            </b-form>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Parametrage</h3>
            </div>
          </div>
          <div class="card-body">
            <b-form-group label="Habitats">
              <b-form-checkbox-group  id="checkbox-group-1" v-model="projet.habitat_id" :options="params.habitats"  name="flavour-1"></b-form-checkbox-group>
            </b-form-group>
            <b-form-group label="Services">
              <b-form-checkbox-group  id="checkbox-group-2" v-model="projet.service_id" :options="params.services"  name="flavour-2"></b-form-checkbox-group>
            </b-form-group>
            <b-form-group label="Solutions">
              <b-form-checkbox-group  id="checkbox-group-3" v-model="projet.solution_id" :options="params.solutions"  name="flavour-3"></b-form-checkbox-group>
            </b-form-group>
            <div class="form-group">
              <label>Location de camion</label>
              <multiselect v-model="truck_source" :options="params.trucks" :multiple="true" track-by="id" label="code"></multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Prestataire</h3>
        </div>
      </div>
      <div class="card-body">
        <b-row>
          <b-col>
            <b-list-group>
              <b-list-group-item href="javascript:void(0)" v-for="(p,i) in prestataires.filter(w => w.status === 1)" :key="i" @click="addPrestatire(p.id)">{{p.nom}} {{p.info.pays}}</b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col>
            <b-list-group>
              <b-list-group-item href="javascript:void(0)" v-for="(p,i) in projet.prestataire_id" :key="i" @click="removePrestaire(i,p)">{{prestataires.find(e => e.id == p).nom}}</b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Multiselect from 'vue-multiselect';
export default {
  data(){
    return {
      truck_source:[],
      projet:{
        id: null,
        nom: null,
        info: {
          url: null,
          logo: null,
          couleur_primaire: "#000000",
          couleur_secondaire: "#000000"
        },
        service_id: [],
        habitat_id: [],
        solution_id: [],
        prestataire_id:[],
        key: null,
      },
      params: {
        habitats:[],
        services:[],
        solutions:[],
        trucks:[]
      },
      prestataires:[]
    };
  },
  components: {
    Multiselect
  },
  watch: {
  },
  methods: {
    save(){
      this.projet.truck_source = [];
      this.truck_source.forEach((item) => {
        this.projet.truck_source.push(item.id);
      });
      this.$store.back.ajax('/projet/update', this.projet, (res) => {
        if(res.status === true){
          this.projet = res.data;
          this.$bvToast.toast(`Votre projet est bien sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
        }
      });
    },
    addPrestatire(id){
      this.prestataires.find(p => p.id == id).status = 0;
      this.projet.prestataire_id.push(id);
    },
    removePrestaire(pos,id){
      this.prestataires.find(p => p.id == id).status = 1;
      this.projet.prestataire_id.splice(pos,1);
    }
  },
  computed: {

  },
  beforeMount(){
    this.$store.back.ajax('/parametrage/all', null, (res) => {
      if(res.status === true){
        res.data.habitats.filter(h => {return h.text = h.nom.fr});
        res.data.habitats.filter(h => {return h.value = h.id});
        res.data.services.filter(h => {return h.text = h.nom.fr});
        res.data.services.filter(h => {return h.value = h.id});
        res.data.solutions.filter(h => {return h.text = h.nom.fr});
        res.data.solutions.filter(h => {return h.value = h.id});
        this.params = res.data;
      }
    });
    this.$store.back.ajax('/prestataire/liste?page=1&limit=1000', null, (res) => {
      res.data.filter(d => d.status = 1);
      this.prestataires = res.data;
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
    this.$store.back.ajax(`/projet/${this.$route.params.id}`, null, (res) => {
      if(res.status === true){
        res.data.prestataire_id.forEach((item) => {
          var check = this.prestataires.find(p => p.id == item);
          if(check){
            check.status = 0;
          }
        });
        this.projet = res.data;
        if(!this.projet.prestataire_id){
          this.projet.prestataire_id = [];
        }
        this.truck_source = [];
        this.projet.truck_source.forEach((item) => {
          var check = this.params.trucks.find(i => i.id === item);
          if(check != undefined){
            this.truck_source.push(check);
          }
        });
      }
    });
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
